/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.7.16/dist/vue.js
 * - /npm/vue-router@3.1.6/dist/vue-router.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/vue-snotify@3.2.1/vue-snotify.min.js
 * - /npm/vue-directive-tooltip@1.6.3/dist/vueDirectiveTooltip.min.js
 * - /npm/animated-number-vue@1.0.0/dist/AnimatedNumber.umd.min.js
 * - /npm/vuetable-2@2.0.0-beta.4/dist/vuetable-2-full.min.js
 * - /npm/vue-search-select@2.9.3/dist/VueSearchSelect.umd.min.js
 * - /npm/vue-simple-suggest@1.10.1/dist/umd.js
 * - /npm/vue2-datepicker@3.7.0/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
